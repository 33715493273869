<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string;
    placeholder?: string;
    modelValue?: string;
    valid?: boolean;
    focus?: boolean;
    error?: string;
    type?: string;
    autocomplete?: string | undefined;
    required?: boolean | undefined;
  }>(),
  {
    placeholder: "",
    valid: true,
    focus: false,
    error: "",
    modelValue: "",
    type: "text",
    required: undefined,
    autocomplete: undefined,
  }
);

const emit = defineEmits(["update:modelValue", "input", "change"]);

const updateValue = (event: any) => {
  emit("update:modelValue", event.target.value);
  emit("input");
};

const element = ref();
const showPassword = ref(false);
const togglePassword = (e:Event) => {
  e.preventDefault();
  showPassword.value = !showPassword.value;
};

if (props.focus) {
  useFocus(element, { initialValue: true });
}
</script>

<template>
  <div class="relative w-full flex-grow">
    <input
      v-bind="$attrs"
      ref="element"
      :id="id"
      :type="(type === 'password' && showPassword) ? 'text' : type"
      :name="id"
      class="w-full rounded border p-2 px-2.5 border-1 border-maas-border-grey-normal focus:outline-1 focus:outline-maas-border-primary"
      :class="{
        'pr-10': type === 'password',
        'border-red-700': !props.valid,
        'border-maas-border-grey-normal': props.valid,
      }"
      :placeholder="placeholder"
      :value="modelValue"
      :autocomplete="autocomplete"
      :required="required"
      @input="updateValue"
      @change="emit('change')"
    />

    <span v-if="type === 'password'" class="absolute flex items-center px-2 right-0 top-0 bottom-0 z-1 cursor-pointer" @click="(e:Event) => togglePassword(e)">
      <svg v-if="showPassword" class="fill-maas-icon-primary" width="20" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.86 2.183-1.804 1.815a4.08 4.08 0 0 0-4.998 4.997L3.243 11.8A11.327 11.327 0 0 1 0 7.935a11.219 11.219 0 0 1 12.85-5.752h.01Zm3.876 1.886A11.385 11.385 0 0 1 20 7.935a11.219 11.219 0 0 1-12.871 5.752l1.805-1.816a4.08 4.08 0 0 0 4.998-4.997l2.815-2.805h-.01ZM16.481 0l1.449 1.448L3.508 15.87 2.06 14.421 16.481 0Z" /></svg>
      <svg v-if="!showPassword" class="fill-maas-icon-primary" width="20" height="13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6.127a11.224 11.224 0 0 1 20 0 11.225 11.225 0 0 1-20 0Zm10 4.081a4.082 4.082 0 1 0 0-8.164 4.082 4.082 0 0 0 0 8.164Zm0-2.04a2.04 2.04 0 1 1 0-4.082 2.04 2.04 0 0 1 0 4.081Z" /></svg>
    </span>
  </div>
  <p v-if="!props.valid" class="text-small mt-2 text-red-700">{{ props.error }}</p>
</template>
